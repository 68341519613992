import "../../App.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { useNavigate } from "react-router-dom";
import { CustomMap } from "../../components/pro_calculator/CustomMap";
import { DepotTable } from "../../components/pro_calculator/tables/DepotTable";
import { AddButton } from "../../components/general/addButton";

function Depot() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const [error, setError] = useState(null);

  const [depotName, setDepotName] = useState("");
  const [depotAddress, setDepotAddress] = useState("");
  const [depotArea, setDepotArea] = useState("");
  const [depotUtility, setDepotUtility] = useState("");
  const [depotPowerCapacity, setDepotPowerCapacity] = useState("");

  const authToken = localStorage.getItem("authToken");

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);
    useEffect(() => {
        axios.get('http://localhost:8000/api/user/', {
            headers: {
                Authorization: `Token ${authToken}`
            }

            
        })
            .then(response => {
                setAuthenticated(true);
                setUserData(response.data);
                
            })
            .catch(error => {
                setAuthenticated(false);
                navigate('/login');
               
            })
    }, [navigate, authToken]);

  console.log(authenticated, userData);

  const data = {
    name: depotName,
    address: depotAddress,
    area: depotArea,
    utility: depotUtility,
    power_capacity: depotPowerCapacity,
  };

  const addDepot = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:8000/api/adddepot/",
        data,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      setError("");
      console.log("response", response);
    } catch (error) {
      setError("Error adding depot");
      return;
    }
  };

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col">
        <h1 className="title-label">Depots</h1>
        <div className="map-dashboard-container">
          <CustomMap />
        </div>
        <div className="dashboard-container">
          <DepotTable />
        </div>
        <AddButton entity={"depot"} position={"bottom-left"} />
      </div>
      {/* <div className='dashboard-container col-md-6 col-md-offset-3'>
    return (
        <div className='container-flex-row'>
            <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
            <div className='container-flex-col'>
                <div className='map-dashboard-container'>
                    <h1>My Depots</h1>
                    <CustomMap />
                </div>
                <div className='dashboard-container'>
                    <DepotTable />
                </div>
            </div>
            <div className='dashboard-container col-md-6 col-md-offset-3'>
                <div className='form-container'>
                    <h3>Add a depot</h3>
                    <form onSubmit={addDepot}>
                        <div className='form-group'>
                            <label htmlFor='inputDepotName'>Depot name</label>
                            <input type="text" className='form-control' value={depotName} onChange={(e) => setDepotName(e.target.value)} required></input>
                            <label htmlFor='inputDepotAddress'>Address</label>
                            <input type="text" className='form-control' value={depotAddress} onChange={(e) => setDepotAddress(e.target.value)} required></input>
                            <label htmlFor='inputDepotArea'>Area</label>
                            <input type="text" className='form-control' value={depotArea} onChange={(e) => setDepotArea(e.target.value)} required></input>
                            <label htmlFor='inputDepotUtility'>Utility</label>
                            <input type="text" className='form-control' value={depotUtility} onChange={(e) => setDepotUtility(e.target.value)} required></input>
                            <label htmlFor='inputPowerCapacity'>Power capacity</label>
                            <input type="text" className='form-control' value={depotPowerCapacity} onChange={(e) => setDepotPowerCapacity(e.target.value)} required></input>
                        </div>
                        <button className='btn btn-default'>Add depot</button>
                    </form>
                    {error && <p className='text-danger'>{error}</p>}
                </div>
            </div> */}
    </div>
  );
}

export default Depot;
