import "../../App.css";
import "./fleet.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { useNavigate } from "react-router-dom";
import { FleetTable } from "../../components/pro_calculator/tables/FleetTable";
import { AddButton } from "../../components/general/addButton";
import Popup from "reactjs-popup";
// import { CustomMap } from "../../components/pro_calculator/CustomMap";

function Fleet() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const [fleetNames, setFleetNames] = useState("");

  const [fleetName, setFleetName] = useState("");
  const [truckName, setTruckName] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState(0);
  const [price, setPrice] = useState(0);
  const [odometer, setOdometer] = useState(0);
  const [fuelType, setFuelType] = useState("diesel");
  const [dailyMileage, setDailyMileage] = useState(0);

  const [newFleetName, setNewFleetName] = useState("");

  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();

  const handleFuelType = (e) => {
    setFuelType(e.target.value);
  };

  const handleFleetName = (e) => {
    setFleetName(e.target.value);
  };

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  const fleetData = {
    name: newFleetName,
  };

  const addFleet = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:8000/api/addfleet/",
        fleetData,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      setError("");
      console.log("response", response);
    } catch (error) {
      setError("Error adding fleet");
      console.log("error", error);
      return;
    }
  };

  console.log(authenticated, authToken);

  const data = {
    truck_id: truckName,
    make: make,
    model: model,
    year: year,
    price: price,
    odometer: odometer,
    fuel_type: fuelType,
    daily_mileage: dailyMileage,
    fleet_name: fleetName,
  };

  const addTruck = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:8000/api/addtruck/",
        data,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      setError("");
      console.log("response", response);
    } catch (error) {
      setError("Error adding truck");
      console.log("error", error);
      return;
    }
  };

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getallfleet/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setFleetNames(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  if (!userData) {
    return <p>Loading ... </p>;
  }

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col">
          <h1 className="title-label">Fleets</h1>
          {/* <CustomMap /> */}
          <AddButton entity={"fleet"} position={"top-right"}/>
        <div className="dashboard-container">
          <FleetTable />
        </div>
          <AddButton entity={"truck"} position={"bottom-left"} />

        {/* <div className="fleet-container">
            <h3>Add a fleet</h3>
          </div> */}
        {/* <div className="form-container col-md-6">
            <form onSubmit={addFleet}>
              <div className="form-group">
                <label htmlFor="inputName">Fleet name</label>
                <input
                  type="text"
                  className="form-control"
                  value={newFleetName}
                  onChange={(e) => setNewFleetName(e.target.value)}
                  required
                ></input>
              </div>
              <button className="btn btn-default">Add fleet</button>
              {error && <p className="text-danger">{error}</p>}
            </form>
          </div> */}

        {/* <div className="dashboard-container">
          <div className="form-container col-md-6 col-md-offset-3">
            <h3>Add a truck</h3>
            <form onSubmit={addTruck}>
              <div className="form-group">
                <label htmlFor="inputTruckName">Truck name</label>
                <input
                  type="text"
                  className="form-control"
                  value={truckName}
                  onChange={(e) => setTruckName(e.target.value)}
                  required
                ></input>
                <label htmlFor="inputMake">Make</label>
                <input
                  type="text"
                  className="form-control"
                  value={make}
                  onChange={(e) => setMake(e.target.value)}
                  required
                ></input>
                <label htmlFor="inputModel">Model</label>
                <input
                  type="text"
                  className="form-control"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                  required
                ></input>
                <label htmlFor="inputYear">Year</label>
                <input
                  type="number"
                  className="form-control"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  required
                ></input>
                <label htmlFor="inputPrice">Price</label>
                <input
                  type="number"
                  className="form-control"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                ></input>
                <label htmlFor="inputOdometer">Odometer</label>
                <input
                  type="number"
                  className="form-control"
                  value={odometer}
                  onChange={(e) => setOdometer(e.target.value)}
                  required
                ></input>
                <label htmlFor="inputFuelType">Fuel type</label>
                <select
                  className="form-control"
                  value={fuelType}
                  onChange={handleFuelType}
                >
                  <option value="Diesel">Diesel</option>
                  <option value="Electric">Electric</option>
                </select>
                <label htmlFor="inputDailyMileage">Daily mileage</label>
                <input
                  type="number"
                  className="form-control"
                  value={dailyMileage}
                  onChange={(e) => setDailyMileage(e.target.value)}
                  required
                ></input>
                <label htmlFor="inputFleetName">
                  Choose a fleet to add the truck to{" "}
                </label>
                <select
                  className="form-control"
                  value={fleetName}
                  onChange={handleFleetName}
                >
                  {fleetNames && fleetNames.length > 0 ? (
                    fleetNames.map((fleet, index) => (
                      <option key={`${fleet.name}-${index}`} value={fleet.name}>
                        {fleet.name}
                      </option>
                    ))
                  ) : (
                    <option value="">No fleets available</option>
                  )}
                </select>
              </div>
              <button className="btn btn-default">Add truck</button>
            </form>
            {error && <p className="text-danger">{error}</p>}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Fleet;
