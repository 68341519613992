import '../App.css';
import InputGroup from '../components/InputGroup.js';
import { useState } from 'react';
import { Navigation } from '../components/navigation.jsx';
import { Piechart } from '../components/Piechart.jsx';
import { SmallHeader } from '../components/smallHeader.jsx';
import JsonData from '../data/data.json';
import ParamData from '../data/parameters.json';
import ImageSlider from '../components/ImageSlider/ImageSlider.js';

function ResetButton({ resetDefault }) {
  return (
    <button
      className="btn btn-custom"
      type="button"
      onClick={resetDefault}
      style={{
        fontSize: "16px",
        textTransform: "none",
        color: "#333",
        backgroundColor: "#c1d1d8",
        backgroundImage: "linear-gradient(to right, #c1d1d8 0%, #c1d1d8 100%)",
        transition: "all 0.3s ease",
      }}
      onMouseEnter={(e) => {
        e.target.style.color = "#fff";
        e.target.style.backgroundColor = "#a0b5bf";
        e.target.style.backgroundImage = "linear-gradient(to right, #a0b5bf 0%, #a0b5bf 100%)";
      }}
      onMouseLeave={(e) => {
        e.target.style.color = "#333";
        e.target.style.backgroundColor = "#c1d1d8";
        e.target.style.backgroundImage = "linear-gradient(to right, #c1d1d8 0%, #c1d1d8 100%)";
      }}
    >
      Reset to Default
    </button>
  );
}

// define the parameters
const initialElectricParams = ParamData.ElectricParams;
const initialDieselParams = ParamData.DieselParams;

const electricImages = [
  {
    url: '/img/trucks/Logo_BYD_Auto.png',
    altUrl: '/img/trucks/E_BYD_8TT.png',
    title: 'BYD 8TT',
    description: 'Model selected',
    brand: 'BYD',
    make: '8TT',
    value: 1,
    altValue: 11
  },
  {
    url: '/img/trucks/Logo_Freightliner.jpg',
    altUrl: '/img/trucks/E_Freightliner_eCascadia.png',
    title: 'Freightliner eCascadia',
    description: 'Model selected',
    brand: 'Freightliner',
    make: 'eCascadia',
    value: 2,
    altValue: 12
  },
  {
    url: '/img/trucks/Logo_Kenworth.png',
    altUrl: '/img/trucks/E_Kenworth_T680E.png',
    title: 'Kenworth T680E',
    description: 'Model selected',
    brand: 'Kenworth',
    make: 'T680E',
    value: 3,
    altValue: 13
  },
  {
    url: '/img/trucks/Logo_Lion_Corporation.png',
    altUrl: '/img/trucks/E_Lion_LION8T.png',
    title: 'Lion LION8T',
    description: 'Model selected',
    brand: 'Lion',
    make: 'LION8T',
    value: 4,
    altValue: 14
  },
  {
    url: '/img/trucks/Logo_Nikola_Motor.png',
    altUrl: '/img/trucks/E_Nikola_TRE.png',
    title: 'Nikola TRE',
    description: 'Model selected',
    brand: 'Nikola',
    make: 'TRE',
    value: 5,
    altValue: 15
  },
  {
    url: '/img/trucks/Logo_Peterbilt.png',
    altUrl: '/img/trucks/E_Peterbilt_579EV.png',
    title: 'Peterbilt 579EV',
    description: 'Model selected',
    brand: 'Peterbilt',
    make: '579EV',
    value: 6,
    altValue: 16
  },
  {
    url: '/img/trucks/Logo_Tesla.png',
    altUrl: '/img/trucks/E_Tesla_Semi.png',
    title: 'Tesla Semi',
    description: 'Model selected',
    brand: 'Tesla',
    make: 'Semi',
    value: 7,
    altValue: 17
  },
  {
    url: '/img/trucks/Logo_Volvo.png',
    altUrl: '/img/trucks/E_Volvo_VNR.png',
    title: 'Volvo VNR',
    description: 'Model selected',
    brand: 'Volvo',
    make: 'VNR',
    value: 8,
    altValue: 18
  },
];

const dieselImages = [
  {
    url: '/img/trucks/Logo_Freightliner.jpg',
    altUrl: '/img/trucks/D_Freightliner_Cascadia.jpg',
    title: 'Freightliner Cascadia',
    description: 'Model selected',
    brand: 'Freightliner',
    make: 'Cascadia',
    value: 1,
    altValue: 11
  },
  {
    url: '/img/trucks/Logo_Kenworth.png',
    altUrl: '/img/trucks/D_Kenworth_T680.png',
    title: 'Kenworth T680',
    description: 'Model selected',
    brand: 'Kenworth',
    make: 'T680',
    value: 2,
    altValue: 12
  },
  {
    url: '/img/trucks/Logo_Kenworth.png',
    altUrl: '/img/trucks/D_Kenworth_W990.jpg',
    title: 'Kenworth W990',
    description: 'Model selected',
    brand: 'Kenworth',
    make: 'W990',
    value: 3,
    altValue: 13
  },
  {
    url: '/img/trucks/Logo_Peterbilt.png',
    altUrl: '/img/trucks/D_Peterbilt_579.png',
    title: 'Peterbilt 579',
    description: 'Model selected',
    brand: 'Peterbilt',
    make: '579',
    value: 4,
    altValue: 14
  },
  {
    url: '/img/trucks/Logo_Peterbilt.png',
    altUrl: '/img/trucks/D_Peterbilt_589.png',
    title: 'Peterbilt 589',
    description: 'Model selected',
    brand: 'Peterbilt',
    make: '589',
    value: 5,
    altValue: 15
  },
  {
    url: '/img/trucks/Logo_Volvo.png',
    altUrl: '/img/trucks/D_Volvo_VNR_64T300.png',
    title: 'Volvo VNR 64T-300',
    description: 'Model selected',
    brand: 'Volvo',
    make: 'VNR 64T300',
    value: 6,
    altValue: 16
  },
];

// Interactive interface here
function SimpleCalculator() {
  const [electricParams, setElectricParams] = useState(initialElectricParams);
  const [dieselParams, setDieselParams] = useState(initialDieselParams);
  const [ownChargers, setOwnChargers] = useState('yes');
  const [defaultElectricParams, setDefaultElectricParams] = useState(initialElectricParams);
  const [defaultDieselParams, setDefaultDieselParams] = useState(initialDieselParams);

  const [selectedElectricBrand, setSelectedElectricBrand] = useState(electricImages[0].brand);
  const [selectedElectricMake, setSelectedElectricMake] = useState(electricImages[0].make);
  const [selectedElectricImage, setSelectedElectricImage] = useState(electricImages[0]);

  const [selectedDieselBrand, setSelectedDieselBrand] = useState(dieselImages[0].brand);
  const [selectedDieselMake, setSelectedDieselMake] = useState(dieselImages[0].make);
  const [selectedDieselImage, setSelectedDieselImage] = useState(dieselImages[0]);

  const [financialExpanded, setFinancialExpanded] = useState(true);
  const [operationalExpanded, setOperationalExpanded] = useState(true);

  const toggleFinancial = () => {
    setFinancialExpanded(!financialExpanded);
  };

  const toggleOperational = () => {
    setOperationalExpanded(!operationalExpanded);
  };

  const electric = electricParams.reduce((acc, param) => {
    acc[param.name] = param.cost;
    return acc;
  }, {});

  const {
    n_EV,
    evMSRP,
    evIncentives,
    evRetailTax,
    evDownPayment,
    evLoanPeriodYear,
    evLoanInterestYear,
    evHoldingPeriod,
    evResidualValue,
    evInsurance,
    evRegistrationFee,
    evAnnualMileage,
    evUnitOMCost,
    kwhpm,
    electricityCost,
    unitChargerCost,
    unitChargerIncentive,
    n_c,
    demandCharge,
    peakLoad,
    thirdPartyFee,
  } = electric;

  const diesel = dieselParams.reduce((acc, param) => {
    acc[param.name] = param.cost;
    return acc;
  }, {});

  const {
    n_d,
    dieselMSRP,
    dieselIncentives,
    dieselRetailTax,
    dieselDownPayment,
    dieselLoanPeriodYear,
    dieselLoanInterestYear,
    dieselHoldingPeriod,
    dieselResidualValue,
    dieselInsurance,
    dieselRegistrationFee,
    dieselAnnualMileage,
    dieselUnitOMCost,
    mpg,
    dieselCost,
    dieselNonZEVcostAnnual,
  } = diesel;

  // additional parameters that need to be defined
  const evPurchaseCost = (evMSRP - evIncentives) * (1 + evRetailTax / 100);
  const evLoanAmount = evPurchaseCost - evDownPayment;
  const evLoanPeriodMonth = evLoanPeriodYear * 12;
  const evLoanInterestMonth = evLoanInterestYear / 12;
  const evMonthlyPayment = evLoanAmount / (((1 + evLoanInterestMonth / 100) ** evLoanPeriodMonth - 1) / (evLoanInterestMonth / 100 * (1 + evLoanInterestMonth / 100) ** evLoanPeriodMonth));

  const dieselPurchaseCost = (dieselMSRP - dieselIncentives) * (1 + dieselRetailTax / 100);
  const dieselLoanAmount = dieselPurchaseCost - dieselDownPayment;
  const dieselLoanPeriodMonth = dieselLoanPeriodYear * 12;
  const dieselLoanInterestMonth = dieselLoanInterestYear / 12;
  const dieselMonthlyPayment = dieselLoanAmount / (((1 + dieselLoanInterestMonth / 100) ** dieselLoanPeriodMonth - 1) / (dieselLoanInterestMonth / 100 * (1 + dieselLoanInterestMonth / 100) ** dieselLoanPeriodMonth));

  // calculate the TCO
  function calculateVehicleCost(vehicleType) {
    if (vehicleType === 'electric') {
      const vehicleCost = ((evMSRP - evIncentives) * (1 + (evRetailTax / 100)) - (evMSRP * evResidualValue / 100)) * n_EV;
      return vehicleCost;
    } else if (vehicleType === 'diesel') {
      const vehicleCost = ((dieselMSRP - dieselIncentives) * (1 + (dieselRetailTax / 100)) - (dieselMSRP * dieselResidualValue / 100)) * n_d;
      return vehicleCost;
    }
  }

  function calculateMaintenanceCost(vehicleType) {
    if (vehicleType === 'electric') {
      const maintenanceCost = evHoldingPeriod * evAnnualMileage * evUnitOMCost;
      return maintenanceCost;
    } else if (vehicleType === 'diesel') {
      const maintenanceCost = dieselHoldingPeriod * dieselAnnualMileage * dieselUnitOMCost;
      return maintenanceCost;
    }
  }

  function calculateInsuranceCost(vehicleType) {
    if (vehicleType === 'electric') {
      const insuranceCost = (evInsurance + evRegistrationFee) * evHoldingPeriod;
      return insuranceCost;
    } else if (vehicleType === 'diesel') {
      const insuranceCost = (dieselInsurance + dieselRegistrationFee) * dieselHoldingPeriod;
      return insuranceCost;
    }
  }

  function calculateChargingCost(own) {
    if (own === 'yes') {
      const chargingCost = (unitChargerCost - unitChargerIncentive) * n_c + ((electricityCost * kwhpm * evAnnualMileage) + (demandCharge * peakLoad * 12)) * evHoldingPeriod;
      return chargingCost;
    } else {
      const chargingCost = thirdPartyFee * kwhpm * evAnnualMileage * evHoldingPeriod;
      return chargingCost;
    }
  }

  function calculateFinanceCost(vehicleType) {
    if (vehicleType === 'electric') {
      const financeCost = evMonthlyPayment * evLoanPeriodMonth - evLoanAmount;
      return financeCost;
    } else if (vehicleType === 'diesel') {
      const financeCost = dieselMonthlyPayment * dieselLoanPeriodMonth - dieselLoanAmount;
      return financeCost;
    }
  }

  function calculateFuelCost() {
    const fuel = dieselHoldingPeriod * dieselAnnualMileage / mpg * dieselCost;
    return fuel;
  }

  function calculateDieselNonZEVCost() {
    const dieselNonZEVcost = dieselNonZEVcostAnnual * dieselHoldingPeriod;
    return dieselNonZEVcost;
  }

  function calculateTCO(vehicleType, own) {
    // calculate TCO    
    const vehicleCost = calculateVehicleCost(vehicleType);
    const financeCost = calculateFinanceCost(vehicleType);
    const maintenanceCost = calculateMaintenanceCost(vehicleType);
    const insuranceCost = calculateInsuranceCost(vehicleType);

    if (vehicleType === 'electric') {
      const chargingCost = calculateChargingCost(own);
      const TCO = vehicleCost + financeCost + maintenanceCost + insuranceCost + chargingCost;
      return TCO;
    } else if (vehicleType === 'diesel') {
      const fuelCost = calculateFuelCost();
      const dieselNonZEVcost = calculateDieselNonZEVCost();
      const TCO = vehicleCost + financeCost + maintenanceCost + insuranceCost + fuelCost + dieselNonZEVcost;
      return TCO;
    }
  }

  function changeDefaultValue(truckNumber, isElectric = true) {
    const params = isElectric ? initialElectricParams : initialDieselParams;

    // First, update the MSRP
    const updatedMSRPParams = params.map(param => {
      if (param.name === (isElectric ? 'evMSRP' : 'dieselMSRP')) {
        let newMSRP;
        switch (truckNumber) {
          case 1: newMSRP = isElectric ? 152000 : 180000; break;
          case 2: newMSRP = isElectric ? 350000 : 190000; break;
          case 3: newMSRP = isElectric ? 350000 : 200000; break;
          case 4: newMSRP = isElectric ? 318000 : 165000; break;
          case 5: newMSRP = isElectric ? 318000 : 200000; break;
          case 6: newMSRP = isElectric ? 318000 : 143000; break;
          case 7: newMSRP = isElectric ? 250000 : 160000; break;
          case 8: newMSRP = isElectric ? 300000 : 160000; break;
          case 11: newMSRP = isElectric ? 152000 : 180000; break;
          case 12: newMSRP = isElectric ? 350000 : 190000; break;
          case 13: newMSRP = isElectric ? 350000 : 200000; break;
          case 14: newMSRP = isElectric ? 318000 : 165000; break;
          case 15: newMSRP = isElectric ? 318000 : 200000; break;
          case 16: newMSRP = isElectric ? 318000 : 143000; break;
          case 17: newMSRP = isElectric ? 250000 : 160000; break;
          case 18: newMSRP = isElectric ? 300000 : 160000; break;
          default: return param;
        }
        return { ...param, cost: newMSRP, default: newMSRP };
      }
      return param;
    });

    // Then, update the Down Payment based on the adjusted MSRP (MSRP - Incentives + Tax)
    const finalUpdatedParams = updatedMSRPParams.map(param => {
      if (param.name === (isElectric ? 'evDownPayment' : 'dieselDownPayment')) {
        const msrpParam = updatedMSRPParams.find(p => p.name === (isElectric ? 'evMSRP' : 'dieselMSRP'));
        const incentivesParam = updatedMSRPParams.find(p => p.name === (isElectric ? 'evIncentives' : 'dieselIncentives'));
        const taxRateParam = updatedMSRPParams.find(p => p.name === (isElectric ? 'evRetailTax' : 'dieselRetailTax'));

        const msrpValue = msrpParam ? msrpParam.cost : 0;
        const incentivesValue = incentivesParam ? incentivesParam.cost : 0;
        const taxRateValue = taxRateParam ? taxRateParam.cost : 0;

        const adjustedMSRP = (msrpValue - incentivesValue) * (1 + taxRateValue / 100);
        const downPaymentValue = adjustedMSRP * 0.2; // 20% of adjusted MSRP
        return { ...param, cost: downPaymentValue, default: downPaymentValue };
      }
      return param;
    });

    if (isElectric) {
      setDefaultElectricParams(finalUpdatedParams);
      setElectricParams(finalUpdatedParams);
    } else {
      setDefaultDieselParams(finalUpdatedParams);
      setDieselParams(finalUpdatedParams);
    }
  }
  const handleElectricBrandChange = (e) => {
    const brand = e.target.value;
    setSelectedElectricBrand(brand);

    // Automatically select the first make associated with the selected brand
    const make = electricImages.find(img => img.brand === brand).make;
    setSelectedElectricMake(make);

    // Update the selected image
    const selectedImage = electricImages.find(img => img.brand === brand && img.make === make);
    setSelectedElectricImage(selectedImage);
    onTruckChange(selectedImage.value, true); // Update parameters based on selected image
  };

  const handleElectricMakeChange = (e) => {
    const make = e.target.value;
    setSelectedElectricMake(make);

    // Update the selected image
    const selectedImage = electricImages.find(img => img.brand === selectedElectricBrand && img.make === make);
    setSelectedElectricImage(selectedImage);
    onTruckChange(selectedImage.value, true); // Update parameters based on selected image
  };

  const handleDieselBrandChange = (e) => {
    const brand = e.target.value;
    setSelectedDieselBrand(brand);

    // Automatically select the first make associated with the selected brand
    const make = dieselImages.find(img => img.brand === brand).make;
    setSelectedDieselMake(make);

    // Update the selected image
    const selectedImage = dieselImages.find(img => img.brand === brand && img.make === make);
    setSelectedDieselImage(selectedImage);
    onTruckChange(selectedImage.value, false); // Update parameters based on selected image
  };

  const handleDieselMakeChange = (e) => {
    const make = e.target.value;
    setSelectedDieselMake(make);

    // Update the selected image
    const selectedImage = dieselImages.find(img => img.brand === selectedDieselBrand && img.make === make);
    setSelectedDieselImage(selectedImage);
    onTruckChange(selectedImage.value, false); // Update parameters based on selected image
  };

  const onChargerChange = e => {
    setOwnChargers(e.target.value);
  }

  const onTruckChange = (imageValue, isElectric = true) => {
    console.log('Truck change triggered:', { imageValue, isElectric });

    const selectedImage = isElectric
      ? electricImages.find(img => img.value === imageValue || img.altValue === imageValue)
      : dieselImages.find(img => img.value === imageValue || img.altValue === imageValue);

    console.log('Selected image:', selectedImage);

    if (isElectric) {
      setSelectedElectricBrand(selectedImage.brand);
      setSelectedElectricMake(selectedImage.make);
      setSelectedElectricImage(selectedImage);
    } else {
      setSelectedDieselBrand(selectedImage.brand);
      setSelectedDieselMake(selectedImage.make);
      setSelectedDieselImage(selectedImage);
    }

    changeDefaultValue(imageValue, isElectric);
  };

  const resetToDefault = () => {
    console.log('resetting to default');
    setElectricParams(defaultElectricParams.map(item => ({ ...item, cost: item.default })));
    setDieselParams(defaultDieselParams.map(item => ({ ...item, cost: item.default })));
  };

  const handleParamChange = (vehicleType, name, value) => {
    console.log('changing');
    let input = value === '' || isNaN(parseFloat(value)) ? value : parseFloat(value);

    if (vehicleType === 'electric') {
      setElectricParams((prevParams) => {
        // First, update the specific parameter value
        const updatedParams = prevParams.map((param) => {
          return param.name === name ? { ...param, cost: input } : param;
        });

        // Then, update the down payment based on the new values
        return updatedParams.map((param) => {
          if (param.name === 'evDownPayment' && (name === 'evMSRP' || name === 'evIncentives' || name === 'evRetailTax')) {
            const msrpParam = updatedParams.find(p => p.name === 'evMSRP');
            const incentivesParam = updatedParams.find(p => p.name === 'evIncentives');
            const taxRateParam = updatedParams.find(p => p.name === 'evRetailTax');

            const msrpValue = msrpParam ? msrpParam.cost : 0;
            const incentivesValue = incentivesParam ? incentivesParam.cost : 0;
            const taxRateValue = taxRateParam ? taxRateParam.cost : 0;

            const adjustedMSRP = (msrpValue - incentivesValue) * (1 + taxRateValue / 100);
            let downPaymentValue = adjustedMSRP * 0.2; // 20% of adjusted MSRP
            downPaymentValue = Math.floor(downPaymentValue * 100) / 100;
            downPaymentValue = downPaymentValue < 0 ? 0 : downPaymentValue;

            return { ...param, cost: downPaymentValue };
          }
          return param;
        });
      });
    } else if (vehicleType === 'diesel') {
      setDieselParams((prevParams) => {
        // First, update the specific parameter value
        const updatedParams = prevParams.map((param) => {
          return param.name === name ? { ...param, cost: input } : param;
        });

        // Then, update the down payment based on the new values
        return updatedParams.map((param) => {
          if (param.name === 'dieselDownPayment' && (name === 'dieselMSRP' || name === 'dieselIncentives' || name === 'dieselRetailTax')) {
            const msrpParam = updatedParams.find(p => p.name === 'dieselMSRP');
            const incentivesParam = updatedParams.find(p => p.name === 'dieselIncentives');
            const taxRateParam = updatedParams.find(p => p.name === 'dieselRetailTax');

            const msrpValue = msrpParam ? msrpParam.cost : 0;
            const incentivesValue = incentivesParam ? incentivesParam.cost : 0;
            const taxRateValue = taxRateParam ? taxRateParam.cost : 0;

            const adjustedMSRP = (msrpValue - incentivesValue) * (1 + taxRateValue / 100);
            let downPaymentValue = adjustedMSRP * 0.2; // 20% of adjusted MSRP
            downPaymentValue = Math.floor(downPaymentValue * 100) / 100;
            downPaymentValue = downPaymentValue < 0 ? 0 : downPaymentValue;

            return { ...param, cost: downPaymentValue };
          }
          return param;
        });
      });
    }

    console.log(calculateTCO(vehicleType, ownChargers));
  };
  const uniqueElectricBrands = [...new Set(electricImages.map(img => img.brand))];
  const uniqueDieselBrands = [...new Set(dieselImages.map(img => img.brand))];

  // Piechart objects
  const eProps = {
    "Vehicle + Tax": Math.round(calculateVehicleCost('electric')),
    "Financing Cost": Math.round(calculateFinanceCost('electric')),
    "Maintenance": Math.round(calculateMaintenanceCost('electric')),
    "Insurance": Math.round(calculateInsuranceCost('electric')),
    "Charging": Math.round(calculateChargingCost(ownChargers)),
  };

  const dProps = {
    "Vehicle + Tax": Math.round(calculateVehicleCost('diesel')),
    "Financing Cost": Math.round(calculateFinanceCost('diesel')),
    "Maintenance": Math.round(calculateMaintenanceCost('diesel')),
    "Insurance": Math.round(calculateInsuranceCost('diesel')),
    "Fuel": Math.round(calculateFuelCost()),
    "Non ZEV Cost": Math.round(calculateDieselNonZEVCost()),
  };
  const evMsrpParam = electricParams.find((param) => param.name === "evMSRP"); // Extract evMSRP param
  const evMsrpCost = evMsrpParam ? evMsrpParam.cost : null;
  return (
    <div>
      <Navigation />
      <SmallHeader data={JsonData.BasicCalculator} />
      <div className='simple-calculator'>
        <div className='container' style={{ marginTop: 15 }}>
          <div className='row'>
            <div className='col-md-12 text-center'>
              {/* <p style={{ fontSize: "21pt", color: "#333", fontWeight: "bold" }}>Fill out the values below and we'll provide an estimate on the total cost of ownership </p> */}
              {/* <p style={{ fontSize: "14pt", color: "#333" }}>Fields are already filled in with typical values that you may edit to fit your fleet's specifications.</p> */}
            </div>
          </div>
          <div className="row model-selection" style={{ paddingTop: 0 }}>
            <div className='col-md-6 electric-truck' style={{ paddingTop: 0, paddingBottom: 5, paddingRight: 50 }}>
              <h3 className='text-center heading' style={{ fontSize: '18pt', marginBottom: '-10px' }}>Electric Truck</h3>
              <div className='col-md-12'>
                <div className="dropdown-container">
                  <div className="dropdown-row">
                    <label className="dropdown-label">Make:</label>
                    <select className="fixed-dropdown" value={selectedElectricBrand} onChange={handleElectricBrandChange}>
                      {uniqueElectricBrands.map((brand, idx) => (
                        <option key={idx} value={brand}>{brand}</option>
                      ))}
                    </select>
                  </div>
                  <div className="dropdown-row" style={{ paddingBottom: 10 }}>
                    <label className="dropdown-label">Model:</label>
                    <select className="fixed-dropdown" value={selectedElectricMake} onChange={handleElectricMakeChange}>
                      {electricImages.filter(img => img.brand === selectedElectricBrand).map((img, idx) => (
                        <option key={idx} value={img.make}>{img.make}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <ImageSlider images={electricImages} selectedImage={selectedElectricImage} onTruckChange={(value) => onTruckChange(value, true)} />
              </div>
            </div>
            <div className='col-md-6 diesel-truck' style={{ paddingTop: 0, paddingLeft: 50, paddingBottom: 5 }}>
              <h3 className='text-center heading' style={{ fontSize: '18pt', marginBottom: '-10px' }}>Diesel Truck</h3>
              <div className='col-md-12'>
                <div className="dropdown-container">
                  <div className="dropdown-row">
                    <label className="dropdown-label">Make:</label>
                    <select className="fixed-dropdown" value={selectedDieselBrand} onChange={handleDieselBrandChange}>
                      {uniqueDieselBrands.map((brand, idx) => (
                        <option key={idx} value={brand}>{brand}</option>
                      ))}
                    </select>
                  </div>
                  <div className="dropdown-row" style={{ paddingBottom: 10 }}>
                    <label className="dropdown-label">Model:</label>
                    <select className="fixed-dropdown" value={selectedDieselMake} onChange={handleDieselMakeChange}>
                      {dieselImages.filter(img => img.brand === selectedDieselBrand).map((img, idx) => (
                        <option key={idx} value={img.make}>{img.make}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <ImageSlider images={dieselImages} selectedImage={selectedDieselImage} onTruckChange={(value) => onTruckChange(value, false)} />
              </div>
            </div>
          </div>
          <div className='row params'>
            <div className='panel-group panel-custom' id='accordion-financial'>
              <div className='panel panel-default'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>
                    <a data-toggle="collapse" data-parent="#accordion-financial" href="#collapse-financial" onClick={toggleFinancial} style={{ color: 'inherit', textDecoration: 'none' }}>
                      <span className={`arrow-icon ${financialExpanded ? 'expanded' : ''}`}></span>
                      <span className={`text`} style={{ paddingLeft: '1%' }}>Financial Parameters</span>
                    </a>
                  </h4>
                </div>
                <div className={`panel-collapse collapse in}`} id='collapse-financial' aria-expanded="true" >
                  <div className='panel-body'>      
                    <div className='row'>
                      <div className='col-md-6 electric-financial-params'>
                        {electricParams.map((param) => (
                          (param.show && param.category === 'financial') && (
                            <InputGroup
                              type="number"
                              key={param.name}
                              label={param.label}
                              description={param.description}
                              defaultValue={param.default}
                              value={param.cost}
                              onChange={(target) => handleParamChange('electric', param.name, target)}
                              evMsrpCost={evMsrpCost}
                            />
                          )
                        ))}
                      </div>
                      <div className='col-md-6 diesel-financial-params'>
                        {dieselParams.map((param) => (
                          (param.show && param.category === 'financial') && (
                            <InputGroup
                              type="number"
                              key={param.name}
                              label={param.label}
                              description={param.description}
                              defaultValue={param.default}
                              value={param.cost}
                              onChange={(target) => handleParamChange('diesel', param.name, target)}
                            />
                          )
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='panel-group panel-custom' id='accordion-operational'>
              <div className='panel panel-default'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>
                    <a data-toggle="collapse" data-parent="#accordion-operational" href="#collapse-operational" onClick={toggleOperational} style={{ color: 'inherit', textDecoration: 'none' }}>
                      <span className={`arrow-icon ${operationalExpanded ? 'expanded' : ''}`}></span>
                      <span className={`text`} style={{ paddingLeft: '1%' }}>Operational Parameters</span>
                    </a>
                  </h4>
                </div>
                <div className={`panel-collapse collapse in}`} id="collapse-operational" aria-expanded="true">
                  <div className='panel-body'>
                    <div className='row'>
                      <div className='col-md-6 electric-operational-params'>
                        {electricParams.map((param) => (
                          (param.show && param.category === 'operational' && param.own === false) && (
                            <InputGroup
                              type="number"
                              key={param.name}
                              label={param.label}
                              description={param.description}
                              defaultValue={param.default}
                              value={param.cost}
                              onChange={(target) => handleParamChange('electric', param.name, target)}
                            />
                          )
                        ))}
                      </div>
                      <div className='col-md-6 diesel-operational-params'>
                        {dieselParams.map((param) => (
                          (param.show && param.category === 'operational') && (
                            <InputGroup
                              type="number"
                              key={param.name}
                              label={param.label}
                              description={param.description}
                              defaultValue={param.default}
                              value={param.cost}
                              onChange={(target) => handleParamChange('diesel', param.name, target)}
                            />
                          )
                        ))}
                      </div>
                    </div>
                    <div className='row charger-selection'>
                      <div className='col-md-6'>
                        <div className='form-check'>
                          <legend>
                            <p style={{ color: "#333", lineHeight: 1.5, fontSize: "14pt" }}>How you plan to charge?</p>
                            <div className='row' style={{ paddingLeft: "6%" }}>
                              <label className='radio own-chargers'>
                                <input
                                  type="radio"
                                  name="group1"
                                  id="own-chargers"
                                  className="radio"
                                  value="yes"
                                  onChange={onChargerChange}
                                  defaultChecked /> <span style={{ fontSize: '12pt' }}>I plan to own my chargers</span>
                              </label>
                            </div>
                            <div className='row' style={{ paddingLeft: "6%" }}>
                              <label className='radio own-chargers'>
                                <input
                                  type="radio"
                                  name="group1"
                                  id="third-party"
                                  className="radio"
                                  value="no"
                                  onChange={onChargerChange} /> <span style={{ fontSize: '12pt' }}>I plan to use commercial charging stations</span>
                              </label>
                            </div>
                          </legend>
                        </div >
                        {ownChargers === 'yes' ? electricParams.map((param) => (
                          (param.show && param.own && !param.thirdParty) && (
                            <InputGroup
                              type="number"
                              key={param.name}
                              label={param.label}
                              description={param.description}
                              defaultValue={param.default}
                              value={param.cost}
                              onChange={(target) => handleParamChange('electric', param.name, target)}
                            />
                          ))
                        ) : electricParams.map((param) => (
                          (param.show && param.thirdParty && param.category === 'operational') && (
                            <InputGroup
                              type="number"
                              key={param.name}
                              label={param.label}
                              description={param.description}
                              defaultValue={param.default}
                              value={param.cost}
                              onChange={(target) => handleParamChange('electric', param.name, target)}
                            />
                          )
                        ))}
                      </div>
                      <div className='col-md-6 drayage-operation'>
                        <div className='row' style={{ paddingLeft: "2%", paddingBottom: "3%" }}>
                          <p style={{ color: "#333", lineHeight: 1.5, fontSize: "14pt" }}>Do you operate out of the Ports of Long Beach and Los Angeles? </p>
                          <p style={{ fontSize: "12pt", lineHeight: 1.5 }}>The Ports of Los Angeles and Long Beach charge $10 per 20-foot equivalent container for non-zero emission trucks.</p>
                          <a href="https://www.portoflosangeles.org/environment/air-quality/clean-truck-program" target="_blank" rel="noopener noreferrer" style={{ fontSize: "11pt" }}>Read more</a>
                        </div>
                        {dieselParams.map((param) => (
                          (param.ZEV) && (
                            <InputGroup
                              type="number"
                              key={param.name}
                              label={param.label}
                              description={param.description}
                              defaultValue={param.default}
                              value={param.cost}
                              onChange={(target) => handleParamChange('diesel', param.name, target)}
                            />
                          )
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col text-center' style={{ marginTop: '20px', marginBottom: '30px' }}>
              <div className='d-flex justify-content-center'>
                <ResetButton resetDefault={resetToDefault} />
              </div>
            </div>
            <div className='row tco-display' style={{ position: 'sticky', bottom: 0, left: 0, right: 0, backgroundColor: 'white', zIndex: 1000, paddingBottom: '0px' }}>
              <div style={{ height: '4px', backgroundColor: '#d6e1e6', width: '100%' }}></div>
              <div className='container' style={{ padding: '15px 0' }}>
                <div className='row'>
                  <div className='col-md-6'>
                    <p className='tco-display'><b>Electric TCO: ${Math.round(calculateTCO('electric', ownChargers)).toLocaleString()} </b></p>
                    <Piechart {...eProps} />
                  </div>
                  <div className='col-md-6'>
                    <p className='tco-display'><b>Diesel TCO: ${Math.round(calculateTCO('diesel', ownChargers)).toLocaleString()} </b></p>
                    <Piechart {...dProps} />
                  </div>
                </div>
                <div className="row text-center" style={{ marginTop: 5 }}>
                  <div className='col-md-8 col-md-offset-2'>
                    {/* <ResetButton resetDefault={resetToDefault} /> */}
                    <p style={{ marginTop: 10, marginBottom: 0, fontSize: '11px' }}>TCO calculator for demonstration only. Results may vary. For detailed fleet analysis, see our upcoming Pro Calculator.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleCalculator;