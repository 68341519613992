import React, { useState, useEffect } from "react";
import "./ChargerTable.css";
import axios from "axios";

export const ChargerTable = () => {
  const [tableData, setTableData] = useState(null);
  const [selectedChargers, setSelectedChargers] = useState({});

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getcharger/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setTableData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  const handleCheckboxChange = (chargerId) => {
    setSelectedChargers((prev) => ({
      ...prev,
      [chargerId]: !prev[chargerId],
    }));
  };

  if (!tableData) {
    return <p>Loading ...</p>;
  }

  return (
    <div className="table-container">
      <div className="table-title">
        <h3 className="charger-header">Your Chargers</h3>
      </div>
      <table className="table">
        <thead>
          <tr className="table-header">
            <th></th>
            <th>ID</th>
            <th>Owner</th>
            <th>Address</th>
            <th>Coordinates</th>
            <th>Chargers</th>
            <th>Charging Fee</th>
            <th>Contact Info</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((depot, index) => (
            <tr className="table-content" key={`${depot.name}-${index}`}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedChargers[depot.id] || false}
                  onChange={() => handleCheckboxChange(depot.id)}
                  className="charger-checkbox"
                />
              </td>
              <td>{index + 1}</td>
              <td>{depot.owner}</td>
              <td>{depot.address}</td>
              <td>{depot.area}</td>
              <td>{depot.utility}</td>
              <td>{depot.power_capacity}</td>
              <td>
                <button className="view-button">View</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
