import "../../App.css";
import './parameters.css';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { ParameterInput } from "../../components/pro_calculator/parameterinput";
import InputGroup from "../../components/InputGroup";

function Parameters() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [test, setTest] = useState("");

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const navigate = useNavigate();

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          setAuthenticated(false);
          navigate("/login");
        }
      });
      return () => {
        source.cancel("Component unmounted, request canceled");
      };
  }, [navigate, authToken]);

  console.log(authenticated, authToken);
  console.log(userData);

  if (!userData) {
    return <p>Loading ... </p>;
  }

  const onChange = (e) => {
    setTest(e.target.value);
  }

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col">
        <div className="dashboard-container">
          <h1 className="title-label">Parameters</h1>
        </div>
        <div className="dashboard-container">
          <InputGroup type="number" label={"test"} description={"test"} id={"test"} defaultValue={0} value={test} onChange={onChange}/>
        </div>
      </div>
    </div>
  );
}

export default Parameters;
