import React from 'react';
import "../../App.css";
import Popup from "reactjs-popup";
import "./addButton.css";
import { GoPlus } from "react-icons/go";
export const AddButton = ( {entity, position} ) => {



  
    return (
        <div className="btn-container">
            <Popup trigger={
            <button className={`add-fleet-button ${position}`}><GoPlus size={19}/> Add a {entity}</button>
          } modal nested>
            {close => (
              <div className="popup-container">
                <div className="close-container">
                  <button className="close" onClick={()=>close()}>&times;</button>
                </div>
                <div className="popup-content">
                  <h3 className="popup-title">Add a {entity}</h3>
                </div>
              </div>
            )}
          </Popup>
        </div>
    )
}