import "../../App.css";
import React, { useState, useEffect } from "react";
import './optimizationpage.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";

function Optimization() {
    const [authenticated, setAuthenticated] = useState(false);
    const [userData, setUserData] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [currentFleet, setCurrentFleet] = useState(null);
    const [fleetNames, setFleetNames] = useState([]);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };


    const navigate = useNavigate();

    const authToken = localStorage.getItem("authToken");

    useEffect(() => {
        axios
            .get("http://localhost:8000/api/user/", {
                headers: {
                    Authorization: `Token ${authToken}`,
                },
            })
            .then((response) => {
                setAuthenticated(true);
                setUserData(response.data);
            })
            .catch((error) => {
                setAuthenticated(false);
                navigate("/login");
            });
    }, [navigate, authToken]);

    useEffect(() => {
        axios
            .get("http://localhost:8000/api/getallfleet/", {
                headers: {
                    Authorization: `Token ${authToken}`,
                },
            })
            .then((response) => {
                setFleetNames(response.data);
                if (response.data.length > 0) {
                    setCurrentFleet(response.data[0].name);
                }
            })
            .catch((error) => {
                console.log("error fleet", error);
            });
    }, [authToken]);

    console.log(authenticated, authToken);
    console.log(userData);

    if (!userData) {
        return <p>Loading ... </p>;
    }

    const handleFleetChange = (e) => {
        setCurrentFleet(e.target.value);
      };

    const optimize = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                "http://localhost:8000/api/optimize/",
                { fleet_name: currentFleet },
                {
                    headers: {
                        Authorization: `Token ${authToken}`,
                    },
                }
            );
            console.log("response", response);
        } catch (error) {
            console.log("error", error);
            return;
        }
    };

    return (
        <div className="container-flex-row">
            <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
            <div className="container-flex-col">
                <div className="dashboard-container">
                    <h1 className="title-label">Calculator</h1>
                </div>
                <div className="dashboard-container">
                    <div className="dropdown-container">
                        <label htmlFor="fleet-select" className="fleet-header">
                            {" "}
                            Your Fleets:{" "}
                        </label>
                        <select
                            id="fleet-select"
                            value={currentFleet}
                            onChange={handleFleetChange}
                        >
                            {fleetNames.map((fleet) => (
                                <option key={fleet.name} value={fleet.name}>
                                    {fleet.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button onClick={optimize}> Optimize </button>
                </div>
            </div>
        </div>
    );
}

export default Optimization;
